import { DynamicImage, Seo, Text } from 'components';
import Header from 'components/Header';
import HeaderNew from 'components/HeaderNew';

import React, { FC } from 'react';
import styled from 'styled-components';
import { mobile, smMobile, useQuery } from 'styles/breakpoints';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useFirstVisitDate, useQuizData } from 'utils/hooks';
import 'swiper/swiper.min.css';
import LoadingBar from './components/LoadingBar';
import { useRouter } from 'apis/history';
import BackGroundQuizImage from '../../../assets/images/lastingChange/quiz/BG-des3.png';
import BlackFridayBanner from 'components/BlackFridayBanner';

SwiperCore.use([Autoplay]);

type ItemProps = {
  img: string;
  img_mob: string;
  text?: string;
};

const Finalizing = () => {
  const data = useQuizData('finalizing');
  const { countdownDiffInMS } = useFirstVisitDate();
  const delay = data?.delay || 1500;
  const duration = data?.duration || 9;

  const { goToEmail } = useRouter();

  const { isMobile } = useQuery();

  if (!data) {
    return null;
  }

  return (
    <Main>
      {data?.bfBanner ? (
        <BlackFridayBanner
          countdownDiffInMS={countdownDiffInMS}
          {...data.bfBanner}
        />
      ) : null}
      <ContainerNew>
        <HeaderContainer>
          <HeaderNew logoVariant="center" sticky={false} />
        </HeaderContainer>
        <ContentWrap>
          {data.title && <Title>{data.title}</Title>}
          <StyledLoadingBar onComplete={goToEmail} duration={duration} />
          <StyledSwiper
            slidesPerView={1}
            noSwiping={true}
            autoplay={{
              delay: delay,
              disableOnInteraction: false,
              stopOnLastSlide: true,
            }}
          >
            {data.imageList.map((item: ItemProps, index: number) => (
              <SwiperSlideStyled key={index}>
                <SlideImage src={isMobile ? item.img_mob : item.img} alt="" />

                {item.text && <StyledText>{item.text}</StyledText>}
              </SwiperSlideStyled>
            ))}
          </StyledSwiper>
        </ContentWrap>
      </ContainerNew>
    </Main>
  );
};

export default Finalizing;

const Main = styled.section`
  padding-top: 4vh;
`;

const SwiperSlideStyled = styled(SwiperSlide)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrap = styled.div`
  box-shadow: rgba(254, 118, 119, 0.45) 0px 6px 40px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  gap: 1.5rem;
  @media ${mobile} {
    padding: 1rem;
  }
`;

const HeaderContainer = styled.div`
  position: relative;
  max-width: 500px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;

  z-index: 1;
  @media ${mobile} {
    margin: unset;
    padding-top: unset;
  }
`;

const Title = styled(Text)`
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
`;
const StyledText = styled(Text)`
  padding-top: 1.5rem;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem;
  text-align: center;
`;

const StyledLoadingBar = styled(LoadingBar)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  @media ${mobile} {
    gap: 1.5rem;
  }
`;

const ContainerNew = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;

  box-shadow: 0px 6px 40px 0px rgba(255, 135, 90, 0.45);
  &:before {
    content: '';
    background: url(${BackGroundQuizImage});
    background-size: cover;
    position: absolute;
    right: 0;
    height: 100vh;
    width: 100%;
    top: 0;
    z-index: -1;
  }

  @media ${mobile} {
    box-shadow: unset;
    padding-top: unset;

    &:before {
      content: '';
      background: unset;
      background-size: cover;
      position: absolute;
      height: unset;
      width: unset;
      z-index: -1;
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  max-width: 28.25rem;
  width: 100%;
  pointer-events: none;
  margin-bottom: 79px;
  @media ${mobile} {
  }
`;

const SlideImage = styled(DynamicImage)`
  width: 100%;
`;
